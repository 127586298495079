'use strict';

var glify = undefined;

module.exports = {
    "debug": {"vertex":"precision mediump float;attribute vec2 a_pos;uniform float u_pointsize;uniform mat4 u_matrix;void main(){gl_Position=u_matrix*vec4(a_pos,step(32767.,a_pos.x),1);gl_PointSize=u_pointsize;}","fragment":"precision mediump float;uniform vec4 u_color;void main(){gl_FragColor=u_color;}"},
    "dot": {"vertex":"precision mediump float;uniform mat4 u_matrix;uniform float u_size;attribute vec2 a_pos;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);gl_PointSize=u_size;}","fragment":"precision mediump float;uniform vec4 u_color;uniform float u_blur;void main(){float a,b;a=length(gl_PointCoord-.5);b=smoothstep(.5,.5-u_blur,a);gl_FragColor=u_color*b;}"},
    "fill": {"vertex":"precision mediump float;uniform mat4 u_matrix;attribute vec2 a_pos;attribute vec4 a_color;varying vec4 a;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);gl_PointSize=2.;a=a_color;}","fragment":"precision mediump float;varying vec4 a;void main(){gl_FragColor=a;}"},
    "gaussian": {"vertex":"precision mediump float;attribute vec2 a_pos;uniform mat4 u_matrix;uniform vec2 u_offset;varying vec2 a[3];void main(){gl_Position=u_matrix*vec4(a_pos,0,1);vec2 b=gl_Position.xy/2.+.5;a[0]=b;a[1]=b+u_offset*1.1824255238063563;a[2]=b-u_offset*1.1824255238063563;}","fragment":"precision mediump float;uniform sampler2D u_image;varying vec2 a[3];void main(){vec4 b=vec4(0);b+=texture2D(u_image,a[0])*.40261994689424746;b+=texture2D(u_image,a[1])*.2986900265528763;b+=texture2D(u_image,a[2])*.2986900265528763;gl_FragColor=b;}"},
    "line": {"vertex":"precision mediump float;attribute vec2 a_pos,a_linewidth;attribute vec4 a_data,a_color;attribute float a_blur;uniform highp mat4 u_matrix;uniform float u_ratio,u_extra;uniform mat2 u_antialiasingmatrix;varying vec2 a,e;varying float b,d,f;varying vec4 c;void main(){vec2 g,h;g=a_data.xy;h=mod(a_pos,2.);h.y=sign(h.y-.5);a=h;vec4 i=vec4(a_linewidth.s*g*.015873016,0,0);gl_Position=u_matrix*vec4(floor(a_pos*.5)+i.xy/u_ratio,0,1);float j,k,l;j=gl_Position.y/gl_Position.w;k=length(g)/length(u_antialiasingmatrix*g);l=1./(1.-j*u_extra);d=l*k;c=a_color;e=a_linewidth;f=a_blur;}","fragment":"precision mediump float;uniform vec2 u_dasharray;varying vec4 c;varying vec2 a,e;varying float b,d,f;void main(){float g,h,i;g=length(a)*e.s;h=f*d;i=clamp(min(g-(e.t-h),e.s-g)/h,0.,1.);gl_FragColor=c*i;}"},
    "linepattern": {"vertex":"precision mediump float;attribute vec2 a_pos,a_linewidth;attribute vec4 a_data;attribute float a_blur,a_opacity;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_ratio;varying vec2 a,c;varying float b,d,e;void main(){vec2 f,h,i,j;f=a_data.xy;float g=a_data.z*128.+a_data.w;h=mod(a_pos,2.);h.y=sign(h.y-.5);a=h;i=f*.015873016;j=a_linewidth.s*i;gl_Position=u_matrix*vec4(floor(a_pos*.5)+j.xy/u_ratio,0,1);b=g;c=a_linewidth;d=a_blur;e=a_opacity;}","fragment":"precision mediump float;uniform float u_point,u_fade;uniform vec2 u_pattern_size_a,u_pattern_size_b,u_pattern_tl_a,u_pattern_br_a,u_pattern_tl_b,u_pattern_br_b;uniform sampler2D u_image;varying vec2 a,c;varying float b,d,e;void main(){float f,g,h,i,j,k;f=length(a)*c.s;g=clamp(min(f-(c.t-d),c.s-f)/d,0.,1.);h=mod(b/u_pattern_size_a.x,1.);i=mod(b/u_pattern_size_b.x,1.);j=.5+a.y*c.s/u_pattern_size_a.y;k=.5+a.y*c.s/u_pattern_size_b.y;vec2 l,m;l=mix(u_pattern_tl_a,u_pattern_br_a,vec2(h,j));m=mix(u_pattern_tl_b,u_pattern_br_b,vec2(i,k));vec4 n=mix(texture2D(u_image,l),texture2D(u_image,m),u_fade);g*=e;gl_FragColor=n*g;}"},
    "linesdfpattern": {"vertex":"precision mediump float;attribute vec2 a_pos,a_linewidth;attribute vec4 a_data,a_color;attribute float a_blur;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_ratio,u_tex_y_a,u_tex_y_b;uniform vec2 u_patternscale_a,u_patternscale_b;varying vec2 a,b,c,e;varying vec4 d;varying float f;void main(){vec2 g,i;g=a_data.xy;float h=a_data.z*128.+a_data.w;i=mod(a_pos,2.);i.y=sign(i.y-.5);a=i;vec4 j=vec4(a_linewidth.s*g*.015873016,0,0);gl_Position=u_matrix*vec4(floor(a_pos*.5)+j.xy/u_ratio,0,1);b=vec2(h*u_patternscale_a.x,i.y*u_patternscale_a.y+u_tex_y_a);c=vec2(h*u_patternscale_b.x,i.y*u_patternscale_b.y+u_tex_y_b);d=a_color;e=a_linewidth;f=a_blur;}","fragment":"precision mediump float;uniform sampler2D u_image;uniform float u_sdfgamma,u_mix;varying vec2 a,b,c,e;varying vec4 d;varying float f;void main(){float g,h,i,j,k;g=length(a)*e.s;h=clamp(min(g-(e.t-f),e.s-g)/f,0.,1.);i=texture2D(u_image,b).a;j=texture2D(u_image,c).a;k=mix(i,j,u_mix);h*=smoothstep(.5-u_sdfgamma,.5+u_sdfgamma,k);gl_FragColor=d*h;}"},
    "outline": {"vertex":"precision mediump float;attribute vec2 a_pos;attribute vec4 a_color;uniform highp mat4 u_matrix;uniform vec2 u_world;varying vec4 a;varying vec2 b;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);b=(gl_Position.xy/gl_Position.w+1.)/2.*u_world;a=a_color;}","fragment":"precision mediump float;varying vec4 a;varying vec2 b;void main(){float c,d;c=length(b-gl_FragCoord.xy);d=smoothstep(1.,0.,c);gl_FragColor=a*d;}"},
    "pattern": {"vertex":"precision mediump float;uniform mat4 u_matrix;uniform mat3 u_patternmatrix_a,u_patternmatrix_b;attribute vec2 a_pos;varying vec2 a,b;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);a=(u_patternmatrix_a*vec3(a_pos,1)).xy;b=(u_patternmatrix_b*vec3(a_pos,1)).xy;}","fragment":"precision mediump float;uniform float u_opacity,u_mix;uniform vec2 u_pattern_tl_a,u_pattern_br_a,u_pattern_tl_b,u_pattern_br_b;uniform sampler2D u_image;varying vec2 a,b;void main(){vec2 c,d,f,g;c=mod(a,1.);d=mix(u_pattern_tl_a,u_pattern_br_a,c);vec4 e,h;e=texture2D(u_image,d);f=mod(b,1.);g=mix(u_pattern_tl_b,u_pattern_br_b,f);h=texture2D(u_image,g);gl_FragColor=mix(e,h,u_mix)*u_opacity;}"},
    "raster": {"vertex":"precision mediump float;uniform mat4 u_matrix;uniform vec2 u_tl_parent;uniform float u_scale_parent,u_buffer_scale;attribute vec2 a_pos,a_texture_pos;varying vec2 a,b;void main(){gl_Position=u_matrix*vec4(a_pos,0,1);a=(a_texture_pos/32767.-.5)/u_buffer_scale+.5;b=a*u_scale_parent+u_tl_parent;}","fragment":"precision mediump float;uniform float u_opacity0,u_opacity1,u_brightness_low,u_brightness_high,u_saturation_factor,u_contrast_factor;uniform sampler2D u_image0,u_image1;varying vec2 a,b;uniform vec3 u_spin_weights;void main(){vec4 c,d,e;c=texture2D(u_image0,a);d=texture2D(u_image1,b);e=c*u_opacity0+d*u_opacity1;vec3 f,h,i;f=e.rgb;f=vec3(dot(f,u_spin_weights.xyz),dot(f,u_spin_weights.zxy),dot(f,u_spin_weights.yzx));float g=(e.r+e.g+e.b)/3.;f+=(g-f)*u_saturation_factor;f=(f-.5)*u_contrast_factor+.5;h=vec3(u_brightness_low);i=vec3(u_brightness_high);gl_FragColor=vec4(mix(h,i,f),e.a);}"},
    "icon": {"vertex":"precision mediump float;attribute vec2 a_pos,a_offset;attribute vec4 a_data1,a_data2;attribute float a_opacity;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_zoom,u_fadedist,u_minfadezoom,u_maxfadezoom,u_fadezoom,u_extra;uniform bool u_skewed;uniform vec2 u_texsize;varying vec2 a;varying float b;void main(){vec2 c,e;c=a_data1.xy;float d,f,g,h,i,j;d=a_data1[2];e=a_data2.st;f=e[0];g=e[1];h=10.;i=2.-step(f,u_zoom)-(1.-step(g,u_zoom));j=clamp((u_fadezoom-d)/u_fadedist,0.,1.);if(u_fadedist>=0.)b=j;else b=1.-j;if(u_maxfadezoom<d)b=0.;if(u_minfadezoom>=d)b=1.;i+=step(b,0.);if(u_skewed){vec4 k=u_exmatrix*vec4(a_offset/64.,0,0);gl_Position=u_matrix*vec4(a_pos+k.xy,0,1);gl_Position.z+=i*gl_Position.w;}else{vec4 k=u_exmatrix*vec4(a_offset/64.,i,0);gl_Position=u_matrix*vec4(a_pos,0,1)+k;}a=c/u_texsize;b*=a_opacity;}","fragment":"precision mediump float;uniform sampler2D u_texture;varying vec2 a;varying float b;void main(){gl_FragColor=texture2D(u_texture,a)*b;}"},
    "sdf": {"vertex":"precision mediump float;attribute vec2 a_pos,a_offset;attribute vec4 a_data1,a_data2,a_color;attribute float a_buffer,a_gamma;uniform highp mat4 u_matrix;uniform mat4 u_exmatrix;uniform float u_zoom,u_fadedist,u_minfadezoom,u_maxfadezoom,u_fadezoom,u_extra;uniform bool u_skewed;uniform vec2 u_texsize;varying vec2 a;varying float b,c,e,f;varying vec4 d;void main(){vec2 g,i;g=a_data1.xy;float h,j,k,l,m,n,o;h=a_data1[2];i=a_data2.st;j=i[0];k=i[1];l=2.-step(j,u_zoom)-(1.-step(k,u_zoom));m=clamp((u_fadezoom-h)/u_fadedist,0.,1.);if(u_fadedist>=0.)b=m;else b=1.-m;if(u_maxfadezoom<h)b=0.;if(u_minfadezoom>=h)b=1.;l+=step(b,0.);if(u_skewed){vec4 n=u_exmatrix*vec4(a_offset/64.,0,0);gl_Position=u_matrix*vec4(a_pos+n.xy,0,1);gl_Position.z+=l*gl_Position.w;}else{vec4 n=u_exmatrix*vec4(a_offset/64.,l,0);gl_Position=u_matrix*vec4(a_pos,0,1)+n;}n=gl_Position.y/gl_Position.w;o=1./(1.-n*u_extra);c=o;a=g/u_texsize;d=a_color;e=a_buffer;f=a_gamma;}","fragment":"precision mediump float;uniform sampler2D u_texture;varying vec2 a;varying float b,c,e,f;varying vec4 d;void main(){float g,h,i;g=f*c;h=texture2D(u_texture,a).a;i=smoothstep(e-g,e+g,h)*b;gl_FragColor=d*i;}"},
    "collisionbox": {"vertex":"precision mediump float;attribute vec2 a_pos,a_extrude,a_data;uniform mat4 u_matrix;uniform float u_scale;varying float a,b;void main(){gl_Position=u_matrix*vec4(a_pos+a_extrude/u_scale,0,1);a=a_data.x;b=a_data.y;}","fragment":"precision mediump float;uniform float u_zoom,u_maxzoom;varying float a,b;void main(){float c=.5;gl_FragColor=vec4(0,1,0,1)*c;if(b>u_zoom)gl_FragColor=vec4(1,0,0,1)*c;if(u_zoom>=a)gl_FragColor=vec4(0,0,0,1)*c*.25;if(b>=u_maxzoom)gl_FragColor=vec4(0,0,1,1)*c*.2;}"}
};
